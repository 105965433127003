.toast {
    min-width: 420px;
    height: auto;
    min-height: 60px;
    padding: 10px;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;
    border-radius: 10px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: none;
    font-size: 16px;
    font-weight: 600;
    z-index: 9999;
    box-shadow: 0px 1px 20px 0px rgba(111, 125, 154, 0.039);
    transition: opacity 0.3s ease-in-out;

    &.hide {
        opacity: 0;
        transform: translateX(-40%);
    }

    .icon {
        width: 32px;
        height: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 13px;
        font-size: 34px;
    }

    .close {
        position: absolute;
        cursor: pointer;
        right: 15px;
        font-size: 20px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        margin-right: 0;
    }

    @media only screen and (max-width: 575px) {
        max-width: calc(100% - 32px);
        left: 16px;
        transform: translateX(0) !important;
        min-width: auto;
    }
}

.toast-green {
    background-color: #60d33d !important;
}

.toast-red {
    background-color: #e24040 !important;
}

.fade-in-up {
    opacity: 0;
    animation: fadeInUp 0.4s ease forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate(-50%, 30px);
    }

    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}