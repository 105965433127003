.login {
    display: flex;
    width: 100%;
    max-width: 568px;
    margin: 0 auto;
    margin-top: 52px;
    justify-content: center;
    flex-direction: column;

    .logo {
        margin-bottom: 52px;
        text-align: center;
    }
}

.login-box {
    border-radius: 16px;
    background: #ffffff;
    padding: 42px 32px;
    box-shadow: 0px 1px 20px 0px rgba(111, 125, 154, 0.039);

    h2 {
        font-size: 28px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-bottom: 50px;

        .icon {
            margin-right: 20px;
            cursor: pointer;
        }
    }

    .btn {
        margin-top: 36px;
        height: 52px;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        padding: 7px 32px 7px 32px;
        gap: 12px;
        background-color: #06694D;
        color: #ffffff;
        border-radius: 54px;

        &.disabled {
            pointer-events: none;
            opacity: 0.8;
        }
    }

    .edit-icon {
        cursor: pointer;
    }

    p.resend-otp {
        margin-bottom: 0 !important;
        margin-top: 30px;
        color: #06694D;
        font-weight: 500;
        cursor: pointer;
        display: table;
    }

    .step-2 {
        h2 {
            margin-bottom: 35px;
        }

        p {
            margin-bottom: 35px;
            line-height: 1;
        }
    }
}

@media only screen and (max-width: 767px) {
    .external-page{
        background-color: #ffffff;
    }

    .login {
        max-width: 504px;
        margin-top: 25px;

        .logo {
            margin-bottom: 40px;
            max-width: 125px;
            margin-left: auto;
            margin-right: auto;
        }

        .login-box {
            box-shadow: none;
            padding: 0;

            h2 {
                margin-bottom: 30px;
                font-size: 24px;
                font-weight: 600;

                .icon {
                    position: absolute;
                    left: 16px;
                    top: 26px;
                }
            }

            &.screen-2 {
                h2 {
                    margin-bottom: 20px;
                }

                p {
                    margin-bottom: 27px;
                }
            }

            .input-wrapper {
                margin-top: 0;
            }

            .btn {
                margin-top: 28px;
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .login {
        padding: 0 16px;
    }
}