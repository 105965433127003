@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');

body {
    font-family: "Inter", sans-serif;
    background-color: #F1F6FE;
    margin: 0;
    color: #1d252d;
}

.success {
    color: #21C179 !important;
}

.error {
    color: #FD5154 !important;
}

.main-header {
    padding: 18px 32px;
    background: #ffffff;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 2px 0px rgba(29, 37, 45, 0.051);
    border-bottom: 1px solid rgba(29, 37, 45, 0.129);

    .hamburger {
        display: none;
    }

    .logo {
        width: 142px;
    }

    .profile {
        font-size: 14px;
        cursor: pointer;
        position: relative;

        .profile-dropdown {
            margin: 0;
            width: 100%;
            position: absolute;
            bottom: -4px;
            right: 0;
            transform: translateY(100%);
            background: #ffffff;
            padding: 0px;
            border-radius: 6px;
            z-index: 4;
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

            >div {
                padding: 7px 12px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
            }
        }

        span {
            background: #F2D8FF;
            width: 36px;
            height: 36px;
            display: inline-block;
            line-height: 36px;
            text-align: center;
            border-radius: 50%;
            font-weight: 500;
            margin-left: 7px;
        }
    }

    .price {
        margin-right: 25px;
        font-size: 16px;
    }

    @media only screen and (max-width: 767px) {
        border: none;
        box-shadow: none;
        padding: 10px 16px;

        .price {
            display: none;
        }

        .hamburger {
            position: absolute;
            left: 16px;
            top: 16px;
            display: flex;
        }

        .logo {
            margin: 0 auto;
            width: 125px;
        }

        .profile {
            display: none;
        }
    }
}

p {
    font-size: 14px;
}

h3 {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    line-height: 36px;
    margin: 0;
}

.page-wrapper {
    display: flex;
    justify-content: flex-start;
    min-height: calc(100vh - 75px);

    .sidebar {
        border-right: 1px solid rgba(29, 37, 45, 0.129);
        width: 80px;
        background: #ffffff;
        position: fixed;
        left: 0;
        z-index: 4;
        top: 74px;
        height: calc(100% - 74px);

        .navbar {
            padding-top: 24px;

            li {
                width: 100%;
                text-align: left;

                .hamburger {
                    background: #F1F6FE;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    margin-left: 24px;
                    margin-bottom: 26px;
                    cursor: pointer;

                    img {
                        width: 18px;
                        height: 18px;
                    }
                }

                a,
                .link {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    min-width: 32px;
                    min-height: 32px;
                    //padding: 1px;
                    margin-left: 24px;
                    margin-bottom: 26px;
                    color: #1d252d;
                    text-decoration: none;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16px;

                    img {
                        width: 26px;
                        height: 26px;
                    }

                    &.active {
                        background: #F1F6FE;
                        border-radius: 50%;

                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }

                .menu-name {
                    display: none;
                    margin-left: 12px;
                }

                &.price {
                    display: none;

                    @media only screen and (max-width: 767px) {
                        display: flex;
                        margin-left: 24px;
                        margin-bottom: 26px;
                    }
                }

                &.hamburger-md {
                    @media only screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }
        }

        @media only screen and (max-width: 767px) {
            top: 54px;
        }

        &.is-open {
            width: 240px;

            .navbar li .menu-name {
                display: flex;
            }
        }
    }

    .page-content {
        width: calc(100% - 80px);
        margin-left: auto;

        @media only screen and (max-width: 767px) {
            width: 100%;
        }

        &.dashboard-page {
            position: relative;

            @media only screen and (max-width: 767px) {
                padding: 24px 16px;
            }
        }

        .no-data {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            padding-top: 50px;

            @media only screen and (max-width: 575px) {
                width: 271px;

                p {
                    font-size: 16px;
                }
            }
        }
    }

    .circle-page {
        background-color: #ffffff;
    }
}

.page-header {
    padding: 87px 20px 0;
    text-align: center;
    background: linear-gradient(95.77deg, #FFCEEE 3.78%, #A3BCFF 97.89%);

    h1 {
        margin: 0;
        line-height: 1;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
    }

    ul {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        gap: 36px;
        justify-content: center;
        margin-top: 55px;

        li {

            a {
                text-decoration: none;
                cursor: pointer;
                color: #1d252d;
                padding: 6px 0px;
                display: block;
                border-bottom: 3px solid transparent;

                &.active {
                    color: #06694D;
                    border-color: #06694D;

                    a,
                    a:hover,
                    a:focus,
                    a:active {
                        color: #06694D;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: 575px) {
        padding: 26px 0 0 16px;

        h1 {
            font-size: 24px;
            font-weight: 600;
            padding-right: 16px;
        }

        ul {
            overflow: auto;
            width: 100%;
            justify-content: space-between;

            li {
                text-wrap: nowrap;
            }
        }
    }
}

.no-data {
    max-width: 342px;
    margin: 0 auto;

    p {
        font-family: Inter, 'sans-serif';
        font-size: 20px;
        font-weight: 600;
        line-height: 24.2px;
        text-align: center;
        color: #1d252d;
        margin-top: 10px;
        margin-bottom: 0;
    }

    .no-data-img {
        text-align: center;
    }
}

.green-btn {
    padding: 7px 32px;
    gap: 12px;
    border-radius: 54px;
    background: #06694D;
    color: #ffffff;
    border: none;
    height: 52px;
    text-wrap: nowrap;
    box-shadow: 0px 0px 18px 8px rgba(68, 131, 105, 0.251);

    &.no-shadow {
        box-shadow: none;
    }

    &.bordered-btn {
        background-color: transparent;
        color: #06694D;
        border: 1px solid #06694D;
        font-weight: 600;
        height: 37px;
        padding: 5px 12px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.red-btn {
    padding: 7px 32px;
    gap: 12px;
    border-radius: 54px;
    background: #FD5154;
    color: #ffffff;
    border: none;
    height: 52px;
    box-shadow: 0px 0px 18px 8px rgba(253, 81, 84, 0.251);

    &.no-shadow {
        box-shadow: none;
    }

    &.bordered-btn {
        background-color: transparent;
        color: #FD5154;
        border: 1px solid #FD5154;
        font-weight: 600;
        height: 37px;
        padding: 5px 12px;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.8;
    }
}

.absolute-center {
    position: fixed;
    bottom: 60px;
    left: calc(50% + 40px);
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 9;
    gap: 22px;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
        left: 50%;
        bottom: 52px;
    }

    @media only screen and (max-width: 575px) {
        width: 100%;
        padding: 0 16px;
        flex-wrap: wrap;
        gap: 12px;

        .green-btn,
        .red-btn {
            width: 100%;
        }
    }
}

.input-wrapper {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .form-control {
        border: 1px solid rgba(29, 37, 45, 0.21);
        padding: 8px 12px;
        height: 52px;
        font-size: 14px;
        border-radius: 8px;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    &.dropdown-input {
        position: relative;
    }

    .input-dropdown {
        list-style: none;
        margin: 0;
        width: 100%;
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        background: #ffffff;
        padding: 0px;
        border-radius: 6px;
        overflow: hidden;
        overflow-y: overlay;
        max-height: 110px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        li {
            font-size: 14px;
            padding: 8px 20px;
            cursor: pointer;

            &:hover {
                background-color: #f7f7f7;
            }
        }
    }
}

.game-modal {
    .circle-modal-wrapper {
        width: 520px !important;
        max-height: 430px !important;

        .input-wrapper {
            margin-top: 35px;
        }

        .member-header {
            margin-top: 0;

            >div:nth-of-type(1) {
                width: calc(70% - 30px);
            }

            .buy-in {
                width: 140px;
            }
        }

        .addmember-row .input-wrapper:nth-of-type(1) {
            width: calc(70% - 30px);

            @media only screen and (max-width: 575px) {
                width: calc(100% - 30px);
            }
        }
    }
}

.number-input {
    position: relative;
    max-width: 140px !important;

    .form-control {
        padding: 8px 32px;
    }

    .minus,
    .plus {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .minus {
        left: 10px;
    }

    .plus {
        right: 10px;
    }
}

.form-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.circle-modal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(29, 37, 45, 0.8);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .circle-modal-wrapper {
        width: 640px;
        max-width: 100%;
        border-radius: 16px;
        background-color: #ffffff;
        padding: 25px;
        position: relative;
        max-height: calc(100% - 32px);
        overflow-y: auto;
        box-shadow: inset 0 0 0 1px #f1f1f1;
        clip-path: inset(0 round 16px);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .close-modal {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
        }

        &.fade-in-up-modal {
            opacity: 0;
            animation: fadeInModal 0.4s ease forwards;
        }

        @keyframes fadeInModal {
            from {
                opacity: 0;
                bottom: -20px;
            }

            to {
                opacity: 1;
                bottom: 0;
            }
        }
    }

    h3 {
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
        text-align: left;
    }

    .input-wrapper:not(.color-input) {
        max-width: 360px;
        width: 100%;
    }

    .circle-radios {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;

        li {
            position: relative;
            max-height: 52px;

            .check-icon {
                position: absolute;
                right: -8px;
                bottom: -8px;
                width: 24px;
                height: 24px;
            }
        }

        .color-radio {
            display: none;
        }

        label {
            width: 52px;
            height: 52px;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #ffffff;
            background-color: #d9d9d9;
        }

        input[type="radio"] {
            &:checked+label {
                border-color: #06694D;
            }
        }
    }

    .invite-para {
        font-size: 12px;
        margin-bottom: 0;
    }

    .member-header {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 25px;
        text-align: left;
        display: flex;
        gap: 20px;
        width: calc(100% - 40px);
        justify-content: flex-start;

        >div {
            width: 50%;
        }
    }

    .addmember-row {
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        gap: 20px;

        .input-wrapper {
            width: calc(50% - 30px);
            margin-top: 0;
        }

        .delete-icon {
            width: 23px;
            height: 23px;
            cursor: pointer;
        }
    }

    .action-btns {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 25px;
        position: sticky;
        bottom: 0px;
        max-width: 250px;
    }

    .add-member-icon {
        color: #ffffff;
        background-color: #06694D;
        height: 52px;
        width: 52px;
        cursor: pointer;
        text-align: center;
        line-height: 52px;
        border-radius: 50%;
        box-shadow: 0px 2px 2px 0px rgba(29, 37, 45, 0.051);
    }

    .circle-members {
        margin-top: 30px;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .mobile-header {
            display: none;
        }
    }

    @media only screen and (max-width: 575px) {
        align-items: flex-end;

        .circle-modal-wrapper {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding: 34px 16px;

            h3 {
                margin-bottom: 10px;
            }

            .input-wrapper {
                margin-top: 24px;
            }

            .circle-radios label {
                width: 42px;
                height: 42px;
            }

            .circle-members {
                max-height: 260px;
                margin-top: 24px;

                .lg-header {
                    display: none;
                }

                .mobile-header {
                    display: block;
                    margin-top: 24px;
                }

                .green-btn {
                    width: 235px;
                }
            }

            .addmember-row {
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                gap: 16px;

                &:not(:last-of-type) {
                    margin-bottom: 15px;
                    padding-bottom: 15px;
                    border-bottom: 1px solid #F1F6FE;
                }

                .input-wrapper {
                    margin-top: 0;
                    width: calc(100% - 30px);
                    max-width: 304px;
                }

                .delete-icon {
                    position: absolute;
                    right: 0;
                    top: 10px;
                }
            }
        }
    }
}

.dashboard-page {
    padding: 55px;
}

.circles-box {
    display: flex;
    gap: 55px;
    flex-wrap: wrap;

    .circle {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 50%;
        cursor: pointer;

        h4 {
            font-size: 20px;
            margin-bottom: 12px;
            line-height: 1;
            font-weight: 600;
        }

        p {
            font-size: 16px;
            line-height: 1;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: 767px) {
        gap: 24px;

        .circle {
            width: 160px;
            height: 160px;

            h4 {
                font-size: 16px;
            }

            p {
                font-size: 12px;
            }
        }
    }
}

.link {
    color: #06694D;
    font-weight: 600 !important;
    cursor: pointer;
    text-decoration: none;
}

.table {
    width: 841px;
    max-width: 100%;
    margin: 0 auto;
    font-size: 14px;

    .thumb-column {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .image {
            width: 36px;
            height: 36px;
            min-width: 36px;
            margin-right: 10px;
            overflow: hidden;
            border-radius: 50%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .text {
            span {
                font-weight: 600;
            }

            .link {
                font-weight: 400;
            }
        }
    }

    tr {
        th {
            background: #F1F6FE;
            padding: 8px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: rgba(29, 37, 45, 0.58);

            @media only screen and (max-width: 575px) {
                padding: 10px 16px !important;
            }

        }

        td {

            @media only screen and (max-width: 575px) {
                padding: 14px 16px !important;
            }
        }

        th,
        td {
            border-bottom: none;
            padding: 16px 32px;
            vertical-align: middle;
        }

        th {
            padding: 11px 32px;
        }

        &:not(:first-of-type) td {
            border-top: 1px solid #E4EBF6;
        }
    }
}

.transaction-details-table {
    .table {
        thead {
            tr {
                &:first-of-type {

                    th {
                        background-color: #E3F6EC;
                        color: #FD5154;
                    }
                }

                &:nth-of-type(2) {
                    th {
                        background-color: #F1F6FE;
                        color: rgba(29, 37, 45, 0.58);
                    }
                }

                &:last-of-type {
                    th {
                        background-color: #FFF8E1;
                        color: #1d252d;

                        &:first-of-type {
                            color: rgba(29, 37, 45, 0.58);
                        }
                    }
                }
            }
        }

        tr {

            th,
            td {
                text-wrap: nowrap;
            }
        }
    }
}

.sticky-table {
    overflow: hidden;
    overflow-x: overlay;
    width: 100%;
    max-width: 841px;
    margin: 0 auto;

    .table {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 203px;
            left: 0;
            top: 0;
            box-shadow: 4px 0px 23px rgba(0, 0, 0, 0.07);
            z-index: 2;
        }

        td:first-child,
        th:first-child {
            position: sticky;
            left: 0;
            z-index: 3;
        }

        td,
        th {
            text-wrap: nowrap;
            position: relative;
            z-index: 1;
        }
    }
}

.prior-games {
    .game-table {
        max-width: 100%;
        width: 680px;
        margin-top: 40px;
        font-weight: 500;

        tr {

            td:nth-of-type(1),
            th:nth-of-type(1) {
                max-width: 203px;
                min-width: 203px;
                width: 203px;
            }

            td:nth-of-type(2),
            th:nth-of-type(2) {
                width: 243px;
            }

            td:nth-of-type(3),
            th:nth-of-type(3) {
                width: 131px;
            }
        }
    }
}

.ongoing-game {
    margin-top: 27px;
    margin-bottom: 100px;

    .game-table {
        max-width: 100%;
        width: 841px;
        margin-top: 27px;
        font-weight: 500;

        .form-control {
            font-size: 14px;
            font-weight: 500;

            &.no-border {
                border: none;
                pointer-events: none;
                padding: 0;
            }
        }

        tr {

            .thumb-column {
                align-items: center;
            }

            td:nth-of-type(1),
            th:nth-of-type(1) {
                max-width: 203px;
                min-width: 203px;
                width: 203px;
            }

            td:nth-of-type(2),
            th:nth-of-type(2) {
                width: 140px;
            }

            td:nth-of-type(3),
            th:nth-of-type(3) {
                width: 173px;
            }

            td:nth-of-type(4),
            th:nth-of-type(4) {
                width: 144px;
            }

            td:nth-of-type(5),
            th:nth-of-type(5) {
                width: 151px;
            }
        }

        @media only screen and (max-width: 575px) {

            td:first-child,
            th:first-child {
                position: -webkit-sticky;
                position: sticky;
                left: 0;
                z-index: 1;
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .ongoing-table {
            overflow: hidden;
            overflow-x: overlay;
        }
    }
}

.game-members {
    .game-table {
        width: 680px;
        margin-top: 40px;
        font-weight: 500;

        tr {
            .thumb-column {
                align-items: center;
            }

            td:nth-of-type(2),
            th:nth-of-type(2) {
                width: 164px;
            }
        }
    }
}

.transactions-page {
    .game-table {
        width: 860px;
        margin-top: 30px;
        font-weight: 500;
        border-radius: 24px;
        overflow: hidden;

        tr {
            .thumb-column {
                align-items: center;
            }

            th {
                background-color: #FFEEDB;
            }

            td {
                background-color: #ffffff;
            }

            td:nth-of-type(1),
            th:nth-of-type(1) {
                width: 230px;
            }

            td:nth-of-type(2),
            th:nth-of-type(2) {
                width: 160px;
            }

            td:nth-of-type(3),
            th:nth-of-type(3) {
                width: 120px;
            }

            @media only screen and (max-width: 767px) {

                td:nth-of-type(1),
                th:nth-of-type(1) {
                    width: 250px;
                }

                td:nth-of-type(2),
                th:nth-of-type(2) {
                    width: 100px;
                }

                td:nth-of-type(3),
                th:nth-of-type(3) {
                    width: 100px;
                }
            }
        }
    }
}

.transactions-page {
    h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        width: 860px;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 30px;
    }
}

.profile-wrapper {
    width: 920px;
    max-width: 100%;
    margin: 0 auto;

    h2 {
        line-height: 36px;
        max-width: 100%;
        margin-top: 48px;
        margin-bottom: 38px;
        font-size: 28px;
        font-weight: 700;
    }

    .profile-box {
        border-radius: 16px;
        background-color: #ffffff;
        padding: 31px 32px 75px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        .profile-image {
            width: 120px;
            height: 120px;
            margin-right: 34px;

            .files-wrapper {
                position: relative;
                width: 100%;
                height: 100%;

                .file-input-wrapper {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    z-index: 2;

                    .file-control {
                        width: 100%;
                        height: 100%;
                    }
                }

                .file-item {
                    position: relative;
                    width: 100%;
                    height: 100%;

                    .camera-icon {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                    }

                    .image {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        overflow: hidden;

                        img {
                            width: 120px;
                            height: 120px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .form {
            width: 328px;
            max-width: 100%;

            .input-wrapper {
                margin-bottom: 16px;
                margin-top: 0;
            }

            .green-btn {
                margin-top: 12px;
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        padding: 0 16px;
    }

    @media only screen and (max-width: 575px) {
        .profile-box {
            flex-direction: column;
            gap: 25px;
            padding: 24px 16px 32px;

            .profile-image {
                width: 132px;
                height: 132px;
                margin: 0;

                .files-wrapper .file-item .image img {
                    width: 132px;
                    height: 132px;
                }
            }
        }

        h2 {
            margin-top: 37px;
            margin-bottom: 27px;
        }
    }
}

.buyIn-box {
    width: 161px;
    height: 32px;
    position: absolute;
    right: -120px;
    top: 22px;

    .number-input {
        margin: 0;
        max-width: 161px !important;
        height: 100%;

        .minus {
            left: 10px;
            top: 6px;
        }

        .plus {
            right: 59px;
            top: 6px;
        }

        .form-control {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            padding: 4px 62px 4px 42px;
            font-size: 12px;
        }
    }

    .green-btn {
        width: 44px;
        height: 24px;
        border-radius: 3px;
        position: absolute;
        right: 8px;
        top: 4px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px;
        line-height: 20px;
    }

    .close-icon {
        right: -28px;
        position: absolute;
        top: 6px;
        cursor: pointer;
    }
}

.relative-box {
    position: relative;
}

.tooltip-icon {
    cursor: pointer;
}

.tooltip-drop {
    background: rgba(29, 37, 45, .95);
    width: 140px;
    color: #ffffff;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 3.5px 0px;
    position: absolute;
    right: -62px;
    top: 30px;
    z-index: 2;

    .edit-icon {
        cursor: pointer;
    }

    &:before {
        content: '';
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 9px solid rgba(29, 37, 45, .95);
        position: absolute;
        top: -9px;
        left: 16px;
    }

    >div {
        padding: 3.5px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.table>:not(caption) .toast .icon {
    background-color: transparent !important;
    padding: 0 !important;
    color: #ffffff !important;
}

.host {
    display: inline-block;
    background: #006b50;
    font-size: 12px;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 3px;
    margin-left: 3px;
    color: #ffffff;
}


@media only screen and (max-width: 767px) {
    .expense-modal {
        .input-wrapper .input-dropdown {
            top: 25px;
        }
    }
}

.transactions-page {
    padding: 0 16px;
}